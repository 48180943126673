<template>
  <div>
    <b-card
      border-variant="primary"
      text-variant="white"
    >
      <div>
        <b-row>
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-info"
              @click="refreshAssigments"
              class="w-100"
            >
              <div v-if="refreshloading">
                <b-spinner small/>
              </div>
              <div v-else>
                <feather-icon
                  icon="RefreshCwIcon"
                />
                Refrescar
              </div>
            </b-button>
          </b-col>
          <b-col cols="12" md="2">
            <RouterLink :to="{ path: `/coordination/assignment/type-credits-all` }">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="info"
                class="w-100"
              >
                <feather-icon
                  icon="BookIcon"
                  class="mr-50"
                />
                Descargar
              </b-button>
            </RouterLink>
          </b-col>      
          <b-col cols="12" md="4">
            <validation-observer 
              ref="assignmentFileRulesValidation"
            >
              <b-form>
                <validation-provider
                  #default="{ errors }"
                  name="archivo de asignación"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    :class="errors.length > 0 ? 'is-invalid' : null" 
                  >
                    <b-form-file
                      v-model="fileAssignment"
                      placeholder="Cargue su asignación aquí..."
                      drop-placeholder="Arrastre su asignación aquí..."
                      accept=".xlsx"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="saveAssignmentFile"
              class="w-100"
            >
              <div v-if="loadAssignmentFile">
                <b-spinner small/>
              </div>
              <div v-else>
                <feather-icon
                  icon="SunriseIcon"
                  class="mr-50"
                />
                <span class="align-middle">Cargar</span>
              </div>
              
            </b-button>
          </b-col>
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              @click="openModalAssignmentBatch"
              class="w-100"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              Crear Lote
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-overlay
      :show="loadingReclamation"
      rounded="sm"
    >
      <b-card>
        <b-row>
          <b-col sm="8">
            <h6>Nombre tipo de reclamación</h6>
          </b-col>
          <b-col sm="2">
            <h6>id tipo de reclamación</h6>
          </b-col>
          <b-col sm="2">
            <h6>Tipo de reclamación</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8" class="d-flex text-center">
            <feather-icon icon="UserPlusIcon" size="20" class="mt-1" />
            <h6 class="mt-1 ml-2">{{ reclamation.name }}</h6>
          </b-col>
          <b-col sm="2" class="d-flex">
            <feather-icon icon="ClipboardIcon" size="20" class="mt-1" />
            <h6 class="mt-1 ml-2">{{ reclamation.id }}</h6>
          </b-col>
          <b-col sm="2" class="d-flex">
            <feather-icon icon="ClipboardIcon" size="20" class="mt-1" />
            <h6 class="mt-1 ml-2">{{ reclamation.type }}</h6>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <b-overlay
      :show="loadingReclamation"
      rounded="sm"
    >
      <b-card>
        <template>
          <b-row>
            <b-col cols="12">
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Buscar</label>
                    <b-form-input v-model="searchTerm" placeholder="Buscar" type="text" class="d-inline-block" />
                  </div>
                </b-form-group>
              </div>
              <vue-good-table :columns="fields" :rows="rows" :search-options="{
                enabled: true,
                externalQuery: searchTerm
                }" :pagination-options="{
                  enabled: true,
                  perPage: pageLength
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'financial'" class="text-nowrap">
                    <b-badge variant="light-danger" v-if="props.row.financial == 'unassigned'">
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>unassigned</span>
                    </b-badge>
                    <b-badge variant="light-warning" v-if="props.row.financial == 'assigned'">
                      <feather-icon icon="UserIcon" class="mr-25" />
                      <span>assigned</span>
                    </b-badge>
                    <b-badge variant="light-success" v-if="props.row.financial == 'finished'">
                      <feather-icon icon="FlagIcon" class="mr-25" />
                      <span>finished</span>
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'legal'" class="text-nowrap">
                    <b-badge variant="light-danger" v-if="props.row.legal == 'unassigned'">
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>unassigned</span>
                    </b-badge>
                    <b-badge variant="light-warning" v-if="props.row.legal == 'assigned'">
                      <feather-icon icon="UserIcon" class="mr-25" />
                      <span>assigned</span>
                    </b-badge>
                    <b-badge variant="light-success" v-if="props.row.legal == 'finished'">
                      <feather-icon icon="FlagIcon" class="mr-25" />
                      <span>finished</span>
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'technique'" class="text-nowrap">
                    <b-badge variant="light-danger" v-if="props.row.technique == 'unassigned'">
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>unassigned</span>
                    </b-badge>
                    <b-badge variant="light-warning" v-if="props.row.technique == 'assigned'">
                      <feather-icon icon="UserIcon" class="mr-25" />
                      <span>assigned</span>
                    </b-badge>
                    <b-badge variant="light-success" v-if="props.row.technique == 'finished'">
                      <feather-icon icon="FlagIcon" class="mr-25" />
                      <span>finished</span>
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'status_actual_phase'" class="text-nowrap">
                    <b-badge variant="light-danger" v-if="props.row.status_actual_phase == 'unassigned'">
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>unassigned</span>
                    </b-badge>
                    <b-badge variant="light-warning" v-if="props.row.status_actual_phase == 'assigned'">
                      <feather-icon icon="UserIcon" class="mr-25" />
                      <span>assigned</span>
                    </b-badge>
                    <b-badge variant="light-success" v-if="props.row.status_actual_phase == 'finished'">
                      <feather-icon icon="FlagIcon" class="mr-25" />
                      <span>finished</span>
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'quality_assignment_status'" class="text-nowrap">
                    <b-badge variant="light-danger" v-if="props.row.quality_assignment_status == 'unassigned'">
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>unassigned</span>
                    </b-badge>
                    <b-badge variant="light-warning" v-if="props.row.quality_assignment_status == 'assigned'">
                      <feather-icon icon="UserIcon" class="mr-25" />
                      <span>assigned</span>
                    </b-badge>
                    <b-badge variant="light-success" v-if="props.row.quality_assignment_status == 'finished'">
                      <feather-icon icon="FlagIcon" class="mr-25" />
                      <span>finished</span>
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'actions'" class="text-nowrap">
                    <b-button v-if="props.row.quality_assignment_status === 'unassigned'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                      class="btn-icon rounded-circle mr-1" @click="storeQualityAssignment(props.row)">
                      <feather-icon icon="CheckCircleIcon" />
                    </b-button>
                    <RouterLink class="user__more"
                      :to="{ path: `/quality/productivity/glosa/${props.row.assignment_id}` }">
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary" class="btn-icon rounded-circle">
                          <feather-icon icon="EyeIcon" />
                      </b-button>
                    </RouterLink>
                  </span>
                </template>
                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <b-form-select v-model="pageLength" :options="['5', '10', '50', '100', '500']" class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                      <span class="text-nowrap ">{{ props.total }} registros encontrados </span>
                    </div>

                    <div>
                      <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                        align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })">
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-overlay>
  </div>
</template>
    
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol, BTable, BInputGroup, BInputGroupAppend, BPopover, BOverlay, BForm, BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es';
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BPopover,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    localize,
    BOverlay,
    BForm,
    BFormFile,
  },
  data() {
    return {
      pageLength: 5,
      perPage: 5,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [],
      items: [],
      reclamationTypeId: this.$route.params && this.$route.params.reclamationTypeId,
      assignmentBatchId: this.$route.params && this.$route.params.assignmentBatchId,
      rows: [],
      searchTerm: '',
      reclamation: {
        name: null,
        id: null,
        type: null,
      },
      loadingReclamation: false,
      refreshloading: false,
      searchTerm: '',
      loadAssignmentFile: false,
      fileAssignment: null,
    }
  },
  directives: {
    Ripple,
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    fetchCreditDetail() {
      this.loadingReclamation = true
      this.$http.get('/quality/productivity/assignment-batch/' + this.assignmentBatchId + '/reclamation-type/' + this.reclamationTypeId).then((response) => {
        this.loadingReclamation = false
        this.rows = response.data.rows
        this.fields = response.data.fields
        this.reclamation = response.data.reclamationType
        this.totalRows = response.data.total
      })
    },
    storeQualityAssignment(assignment) {
      const formData = new FormData();
      formData.append('assignment_id', assignment.assignment_id);
      this.$http.post('/quality/productivity/assignment/store-quality-assignment', formData).then(response => { 
        this.fetchCreditDetail()
        this.$swal({
          title: response.data.message,
          icon: 'success',
          customClass: {
                confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        });
      })
      .catch (error => {
        this.$swal({
          title: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        });
      })
    },
    showGlosa(data) {
      this.$router.push('/auditory/glosa/' + data)
    },
    statusStyle(status) {

    },
    refreshAssigments() {
      this.refreshloading = true
      this.getAssignmentBatches()
      this.refreshloading = false
    },
    saveAssignmentFile() {
      localize('es', es)
      this.$refs.assignmentFileRulesValidation.validate().then(success => {
        if (success) {
          this.loadAssignmentFile = true
          let formData = new FormData()
          formData.append('file', this.fileAssignment)
          this.$http.post('coordination/assignment/import-assignment', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
            this.loadAssignmentFile = false
            this.getAssignmentBatches()
            this.fileAssignment = null
            this.$refs.assignmentFileRulesValidation.reset()
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                    confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.fileAssignment = null
            this.loadAssignmentFile = false
            this.$refs.assignmentFileRulesValidation.reset()
            this.viewErrors(error.response.data.errors)
            this.$refs.ErrorImport.openModalErrors();
          })
        }
      })
    },
    closeModalAssignmentBatch() {
      this.$refs['modal-assignment-batch'].hide()
      this.$refs.assignmentRulesValidation.reset()
      this.assignmentBatchResetForm()
    },
    openModalAssignmentBatch() {
      this.$refs['modal-assignment-batch'].show()
    },
  },
  created() {
    this.fetchCreditDetail()
  },
  mounted() {
    this.totalRows = this.items.length
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';


</style>
<style scoped lang="css">
.vgt-left-align {
  white-space: nowrap !important;
}

</style>
